// HomePage.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/styles/styles.css";
import HomeViewersBar from "../components/HomeViewersBar";

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context(
    "../assets/images/gandhi_ashram",
    false,
    /\.(png|jpe?g|svg|JPG)$/
  )
);

const HomePage = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isPopupVisible, setPopupVisible] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  return (
    <div className="con">
      {isPopupVisible && (
        <div className="popup">
          <div className="popup-content">
            <button className="close-button" onClick={closePopup}>
              &times;
            </button>
            <h2>Welcome!</h2>
            <img
              src={require("../assets/images/icons/logo.png")}
              alt="Popup"
              className="popup-image"
            />
            <p></p>
          </div>
        </div>
      )}
      <h2>Welcome to Gandhi Ashram</h2>
      <HomeViewersBar />
      <div className="content">
        <div className="slideshow">
          <img
            src={images[currentImageIndex]}
            alt="Gandhi Ashram"
            className="slideshow-image"
          />
        </div>
        <div className="notification-bar">
          <div className="notification-text">
            <p>K.CHIDAMBARAM President K.சிதம்பரம் தலைவர்</p>
            <p>S.KRISHNAMOORTHY Secretary S.கிருஷ்ணமூர்த்தி செயலாளர்</p>
            <p>R.KUMAR, TREASURER ஆர்.குமார், பொருளாளர்</p>
          </div>
          <div className="photos">
            <div className="photo-item">
              <img
                src={require("../assets/images/pr.jpeg").default}
                alt="K.CHIDAMBARAM"
                className="person-photo"
              />
              <p>K.CHIDAMBARAM President K.சிதம்பரம் தலைவர்</p>
            </div>
            <div className="photo-item">
              <img
                src={require("../assets/images/scer.jpeg").default}
                alt="S.KRISHNAMOORTHY"
                className="person-photo"
              />
              <p>S.KRISHNAMOORTHY Secretary S.கிருஷ்ணமூர்த்தி செயலாளர்</p>
            </div>
            <div className="photo-item">
              <img
                src={require("../assets/images/account.jpeg").default}
                alt="R.KUMAR"
                className="person-photo"
              />
              <p>R.KUMAR, TREASURER ஆர்.குமார், பொருளாளர்</p>
            </div>
          </div>
        </div>
      </div>
      <div className="info-sections">
        <div
          className="info-section"
          onClick={() => handleNavigation("/history")}
        >
          <img
            src={require("../assets/images/icons/tour.png")}
            alt="Tour"
            className="info-icon"
          />
          <h3>Tour</h3>
          <p>Explore the Gandhi Ashram and its history.</p>
        </div>
        <div
          className="info-section"
          onClick={() => handleNavigation("/gallery")}
        >
          <img
            src={require("../assets/images/icons/activities.png")}
            alt="Activity"
            className="info-icon"
          />
          <h3>Gallery</h3>
          <p></p>
        </div>
        <div className="info-section" onClick={() => handleNavigation("/info")}>
          <img
            src={require("../assets/images/icons/visit.png")}
            alt="Sites"
            className="info-icon"
          />
          <h3>Sites</h3>
          <p>Visit's</p>
        </div>
        <div
          className="info-section"
          onClick={() => handleNavigation("/products")}
        >
          <img
            src={require("../assets/images/icons/image.png")}
            alt="Products"
            className="info-icon"
          />
          <h3>Products</h3>
          <p>Discover and purchase products made at the Ashram.</p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
