import React, { useState } from "react";
import "../assets/styles/photospages.css";

// Function to import all images from a given context
const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

const katherProductionPhotos = importAll(
  require.context(
    "../assets/images/Kather_Production_Photos",
    false,
    /\.(png|jpe?g|svg|JPG)$/
  )
);
const Anniversaryphotos = importAll(
  require.context(
    "../assets/images/Anniversary_Function",
    false,
    /\.(png|jpe?g|svg|JPG)$/
  )
);
const Waterplant = importAll(
  require.context(
    "../assets/images/water_plant",
    false,
    /\.(png|jpe?g|svg|JPG)$/
  )
);
const agarbathiTraining = importAll(
  require.context(
    "../assets/images/Agarbathi_Training_Photos",
    false,
    /\.(png|jpe?g|svg|JPG)$/
  )
);
const ahram = importAll(
  require.context(
    "../assets/images/gandhi_ashram",
    false,
    /\.(png|jpe?g|svg|JPG)$/
  )
);
const funcations = importAll(
  require.context(
    "../assets/images/funcations",
    false,
    /\.(png|jpe?g|svg|JPG)$/
  )
);
const rajaji = importAll(
  require.context(
    "../assets/images/Rajajai_Birth_House",
    false,
    /\.(png|jpe?g|svg|JPG)$/
  )
);
const anniversary1 = importAll(
  require.context(
    "../assets/images/anniversary50",
    false,
    /\.(png|jpe?g|svg|JPG)$/
  )
);
const anniversary2 = importAll(
  require.context(
    "../assets/images/anniversary75",
    false,
    /\.(png|jpe?g|svg|JPG)$/
  )
);
const agarbathi = importAll(
  require.context("../assets/images/agarbathi", false, /\.(png|jpe?g|svg|JPG)$/)
);
const file = importAll(
  require.context(
    "../assets/images/file_images",
    false,
    /\.(png|jpe?g|svg|JPG)$/
  )
);

const photos = {
  GandhiAhsram: Object.values(ahram),
  Functions: Object.values(funcations),
  RajajiBrithHouse: Object.values(rajaji),
  Anniversary50: Object.values(anniversary1),
  Anniversary75: Object.values(anniversary2),
  anniversary91: Object.values(Anniversaryphotos),
  katherproduction: Object.values(katherProductionPhotos),
  AgarabathiTraining: Object.values(agarbathiTraining),
  Waterplantopening: Object.values(Waterplant),
  agarbathiProduction: Object.values(agarbathi),
  FileProduction: Object.values(file),
};

const PhotosPage = () => {
  const [selectedCategory, setSelectedCategory] = useState("nature");

  return (
    <div className="photos-container">
      <h2>Photos</h2>
      <div className="category-list">
        {Object.keys(photos).map((category) => (
          <button
            key={category}
            className={selectedCategory === category ? "active" : ""}
            onClick={() => setSelectedCategory(category)}
          >
            {category.charAt(0).toUpperCase() + category.slice(1)}
          </button>
        ))}
      </div>
      {photos[selectedCategory] && (
        <div className="photo-grid">
          {photos[selectedCategory].map((photo, index) => (
            <img
              key={index}
              src={photo}
              alt={`${selectedCategory} ${index + 1}`}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default PhotosPage;
